html,
body {
  height: 97%;
  margin: 0;
  background-color: $gray-700;
  font-family: $font-family;

  h1 {
    margin: 2vmin;
    text-align: center;
  }
}
