.overlay.chat-overlay {
  .body {
    overflow-y: clip;
    padding: 0;
    position: relative;
    height: calc(100% - 2.1vh);

    .history {
      height: calc(100% - 13.5vh);
      overflow-y: auto;
      padding: 2vmin;
      margin-bottom: 2px; // for scrollbar
    }

    .input {
      position: relative;
      margin: -2px;
      height: 10vh;

      textarea {
        margin: 0;
        background-color: $gray-200;
        border: 2px solid black;
        border-radius: 0 0 0.9vmin 0.9vmin;
        height: calc(9vh - 11px);
        padding: 0.5vh 10vh 0.5vh 0.5vh;
        width: calc(100% - 10vh - 8px);
        resize: none;
      }

      .submit-input-button {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 3vh;
        margin-right: 3vh;
        z-index: 10;
      }
    }
  }
}
