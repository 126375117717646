.dashboard {
  h1 {
    color: $white;
  }

  .control-panel {
    display: flex;
    flex-direction: column;
    width: auto;
    border-radius: 1vmin;
    margin: 2vh;
    margin-top: 4vh;
    background-color: $gray-600;

    >.title {
      color: $gray-100;
      text-align: center;

      h3 {
        margin: 0.5vmin;
        font-size: 1.7vh;
        text-transform: uppercase;
      }

      p {
        margin: 1vmin;
        margin-top: -0.5vmin;
      }
    }

    .body {
      color: $gray-800;
      border-radius: 0 0 1vmin 1vmin;
      background-color: $gray-200;
    }

    .app-position {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex: 1;
      margin: 1vmin;
    }

    .position-element {
      text-align: center;

      .position {
        font-weight: 600;
        font-size: 1.5vmin;
      }

      .title {
        font-size: 1.5vmin;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 78.3vh;

    pre {
      white-space: pre-wrap;
    }

  }


}
