.overlay {
  .history-item {
    border-top: 1px solid $gray-500;

    &.history-item-tutor {
      color: $primary;
    }

    pre {
      white-space: pre-wrap;

      .role {
        text-align: center;
        font-weight: 800;
        text-transform: uppercase;
      }
    }

  }

}
