.loading {
  height: 100vh;
  background-color: $gray-700;

  .body {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;

  }

  .headline {
    color: white;
  }
}
