.popup-overlay {

  .popup-content {
    border: 1vmin $primary solid;
    border-radius: 2vmin;
    background-color: white;

    .modal {
      text-align: center;
      margin: 2rem;
      border-radius: 1vmin;

      .title {
        font-weight: bold;
      }

      .body {
        margin-top: 1.5rem;
      }

      .actions {
        margin-top: 1.5rem;

        button {
          margin-left: 1.5rem;
          margin-right: 1.5rem;
        }
      }
    }

  }

}
