.navigation-panel {

  .select-app-button {
    margin-top: 0.5vmin;
    margin-bottom: 0.5vmin;
  }

  .navigation-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5vmin;
    margin-bottom: 0.5vmin;

    .icon-group {
      display: flex;
      gap: 10px; // Adjust the space between buttons

      .icon-button:disabled {
        background-color: transparent;
        color: $gray-500;
      }
    }

    .title {
      margin-left: 2vmin;
      margin-right: 2vmin;
      text-transform: uppercase;
      font-size: 1.7vmin;
      font-weight: bold;
    }
  }

  .program-position {
    display: flex;
    justify-content: space-around;
    margin-top: 1vmin;
    margin-bottom: 1vmin;

    .position-element {
      flex: 1;
    }
  }

  .controls {
    margin: 1vmin;
    font-size: 1.5vmin;

  }
}
