// Icon buttons
.icon-button {
  display: flex;
  border-color: transparent;
  background-color: $primary;
  border-radius: 50%;
  width: 4vmin;
  height: 4vmin;
  color: white;
  justify-content: center;
  align-items: center;

  &:active {
    background-color: green;
  }

  &:hover {
    background-color: green;
  }

  &:disabled {
    background-color: $gray-500;
  }

  .svg-inline--fa {
    height: 70%;
  }

  &.title-bar-button {
    background-color: $gray-600;
    float: right;
    margin-right: 1vmin;
    height: 2.5vmin;
    width: 2.5vmin;

    &.float-left-button {
      float: left;
      margin-right: 0;
      margin-left: 1vmin;
    }

    &.transparent-button {
      background-color: transparent;

      &:hover,
      &:active {
        background-color: transparent;
      }
    }

    &:hover,
    &:active {
      background-color: green;
    }
  }
}

.btn {
  margin-top: 2rem;
  margin-left: 1vmin;
  margin-right: 1vmin;
  border-radius: 0.3rem;

  &.btn-primary {
    background-color: $primary;
    color: white;
    border-color: $primary;

    &:hover {
      background-color: $primary-hover;
    }
  }
}
