h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", $font-family;
  color: $primary;
}

button {
  font-family: "Montserrat", $font-family;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
