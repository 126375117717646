.login {
  flex-direction: column;
  background-color: $gray-700;
  height: 100vh;

  .form-body {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;

      h3 {
        font-size: 2rem;
        color: white;
      }

      a {
        color: $gray-400;
      }

    }

    .input-container {
      margin: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .input-box {
        height: 48px;
        width: 400px;
        font-size: large;
        border-radius: 8px;
        border: 1px solid grey;
        padding-left: 8px;
      }
    }

    .actions {
      button {
        font-size: 1rem;
      }
    }
  }

}
