/*
 * Declare variables used in scss
 */
/* Import Google font - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
html,
body {
  height: 97%;
  margin: 0;
  background-color: #495057;
  font-family: "Poppins", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
html h1,
body h1 {
  margin: 2vmin;
  text-align: center;
}

.icon-button {
  display: flex;
  border-color: transparent;
  background-color: #0d6efd;
  border-radius: 50%;
  width: 4vmin;
  height: 4vmin;
  color: white;
  justify-content: center;
  align-items: center;
}
.icon-button:active {
  background-color: green;
}
.icon-button:hover {
  background-color: green;
}
.icon-button:disabled {
  background-color: #adb5bd;
}
.icon-button .svg-inline--fa {
  height: 70%;
}
.icon-button.title-bar-button {
  background-color: #6c757d;
  float: right;
  margin-right: 1vmin;
  height: 2.5vmin;
  width: 2.5vmin;
}
.icon-button.title-bar-button.float-left-button {
  float: left;
  margin-right: 0;
  margin-left: 1vmin;
}
.icon-button.title-bar-button.transparent-button {
  background-color: transparent;
}
.icon-button.title-bar-button.transparent-button:hover, .icon-button.title-bar-button.transparent-button:active {
  background-color: transparent;
}
.icon-button.title-bar-button:hover, .icon-button.title-bar-button:active {
  background-color: green;
}

.btn {
  margin-top: 2rem;
  margin-left: 1vmin;
  margin-right: 1vmin;
  border-radius: 0.3rem;
}
.btn.btn-primary {
  background-color: #0d6efd;
  color: white;
  border-color: #0d6efd;
}
.btn.btn-primary:hover {
  background-color: #0256d3;
}

.card {
  flex: 1;
  margin: 2vmin;
  border: 0.3vmin solid #0d6efd;
  border-radius: 1vmin;
  background-color: #fff;
}

.card-title {
  font-weight: bold;
  font-size: 1.7vh;
  text-transform: uppercase;
  background-color: #0d6efd;
  color: #fff;
  text-align: center;
}

.card-body {
  padding: 2vh;
  overflow-y: auto;
  height: 67vh;
  border-radius: 0 0 1vmin 1vmin;
  font-size: min(14px, 1.3vh);
  font-family: monospace;
}

.dashboard-panel {
  flex: 1;
  margin-left: 2vmin;
  margin-right: 2vmin;
}
.dashboard-panel .card {
  margin-left: 0;
  margin-right: 0;
}
.dashboard-panel .card .card-body {
  height: 29vh;
  border-radius: 0 0 1vmin 1vmin;
}
.dashboard-panel .card textarea.card-body {
  width: 100%;
  height: 26vh;
  margin-top: -0.1vh;
  border-color: transparent;
  box-sizing: border-box;
  resize: none;
}

.overlay.chat-overlay .body {
  overflow-y: clip;
  padding: 0;
  position: relative;
  height: calc(100% - 2.1vh);
}
.overlay.chat-overlay .body .history {
  height: calc(100% - 13.5vh);
  overflow-y: auto;
  padding: 2vmin;
  margin-bottom: 2px;
}
.overlay.chat-overlay .body .input {
  position: relative;
  margin: -2px;
  height: 10vh;
}
.overlay.chat-overlay .body .input textarea {
  margin: 0;
  background-color: #e9ecef;
  border: 2px solid black;
  border-radius: 0 0 0.9vmin 0.9vmin;
  height: calc(9vh - 11px);
  padding: 0.5vh 10vh 0.5vh 0.5vh;
  width: calc(100% - 10vh - 8px);
  resize: none;
}
.overlay.chat-overlay .body .input .submit-input-button {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 3vh;
  margin-right: 3vh;
  z-index: 10;
}

.dashboard h1 {
  color: #fff;
}
.dashboard .control-panel {
  display: flex;
  flex-direction: column;
  width: auto;
  border-radius: 1vmin;
  margin: 2vh;
  margin-top: 4vh;
  background-color: #6c757d;
}
.dashboard .control-panel > .title {
  color: #f8f9fa;
  text-align: center;
}
.dashboard .control-panel > .title h3 {
  margin: 0.5vmin;
  font-size: 1.7vh;
  text-transform: uppercase;
}
.dashboard .control-panel > .title p {
  margin: 1vmin;
  margin-top: -0.5vmin;
}
.dashboard .control-panel .body {
  color: #343a40;
  border-radius: 0 0 1vmin 1vmin;
  background-color: #e9ecef;
}
.dashboard .control-panel .app-position {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
  margin: 1vmin;
}
.dashboard .control-panel .position-element {
  text-align: center;
}
.dashboard .control-panel .position-element .position {
  font-weight: 600;
  font-size: 1.5vmin;
}
.dashboard .control-panel .position-element .title {
  font-size: 1.5vmin;
}
.dashboard .content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 78.3vh;
}
.dashboard .content pre {
  white-space: pre-wrap;
}

.overlay .history-item {
  border-top: 1px solid #adb5bd;
}
.overlay .history-item.history-item-tutor {
  color: #0d6efd;
}
.overlay .history-item pre {
  white-space: pre-wrap;
}
.overlay .history-item pre .role {
  text-align: center;
  font-weight: 800;
  text-transform: uppercase;
}

.loading {
  height: 100vh;
  background-color: #495057;
}
.loading .body {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.loading .headline {
  color: white;
}

.login {
  flex-direction: column;
  background-color: #495057;
  height: 100vh;
}
.login .form-body {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login .form-body .heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.login .form-body .heading h3 {
  font-size: 2rem;
  color: white;
}
.login .form-body .heading a {
  color: #ced4da;
}
.login .form-body .input-container {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login .form-body .input-container .input-box {
  height: 48px;
  width: 400px;
  font-size: large;
  border-radius: 8px;
  border: 1px solid grey;
  padding-left: 8px;
}
.login .form-body .actions button {
  font-size: 1rem;
}

.popup-overlay .popup-content {
  border: 1vmin #0d6efd solid;
  border-radius: 2vmin;
  background-color: white;
}
.popup-overlay .popup-content .modal {
  text-align: center;
  margin: 2rem;
  border-radius: 1vmin;
}
.popup-overlay .popup-content .modal .title {
  font-weight: bold;
}
.popup-overlay .popup-content .modal .body {
  margin-top: 1.5rem;
}
.popup-overlay .popup-content .modal .actions {
  margin-top: 1.5rem;
}
.popup-overlay .popup-content .modal .actions button {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #eb773e;
  padding: 1vmin 2vmin 1vmin 2vmin;
  background-color: rgb(244, 247, 249);
  border: 1px solid #dee2e6;
  font-size: small;
  font-weight: bold;
  font-family: "Montserrat", "Poppins", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.navbar .navbar-brand {
  display: flex;
  align-items: center;
}
.navbar .navbar-brand .icon {
  width: 1.5rem;
}
.navbar .navbar-brand .title {
  margin-left: 1rem;
  text-transform: uppercase;
}
.navbar .logout-button {
  background-color: #0d6efd;
}

.navigation-panel .select-app-button {
  margin-top: 0.5vmin;
  margin-bottom: 0.5vmin;
}
.navigation-panel .navigation-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5vmin;
  margin-bottom: 0.5vmin;
}
.navigation-panel .navigation-title-container .icon-group {
  display: flex;
  gap: 10px;
}
.navigation-panel .navigation-title-container .icon-group .icon-button:disabled {
  background-color: transparent;
  color: #adb5bd;
}
.navigation-panel .navigation-title-container .title {
  margin-left: 2vmin;
  margin-right: 2vmin;
  text-transform: uppercase;
  font-size: 1.7vmin;
  font-weight: bold;
}
.navigation-panel .program-position {
  display: flex;
  justify-content: space-around;
  margin-top: 1vmin;
  margin-bottom: 1vmin;
}
.navigation-panel .program-position .position-element {
  flex: 1;
}
.navigation-panel .controls {
  margin: 1vmin;
  font-size: 1.5vmin;
}

.prior-results-overlay pre {
  white-space: pre-wrap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", "Poppins", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #0d6efd;
}

button {
  font-family: "Montserrat", "Poppins", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}