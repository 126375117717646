.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #eb773e;
  padding: 1vmin 2vmin 1vmin 2vmin;
  background-color: rgb(244, 247, 249);
  border: 1px solid #dee2e6;
  font-size: small;
  font-weight: bold;
  font-family: "Montserrat", $font-family;

  .navbar-brand {
    display: flex;
    align-items: center;

    .icon {
      width: 1.5rem;
    }

    .title {
      margin-left: 1rem;
      text-transform: uppercase;
    }
  }

  .logout-button {
    background-color: $primary
  }
}
