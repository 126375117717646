.card {
  flex: 1;
  margin: 2vmin;
  border: 0.3vmin solid $primary;
  border-radius: 1vmin;
  background-color: $white;
}

.card-title {
  font-weight: bold;
  font-size: 1.7vh;
  text-transform: uppercase;
  background-color: $primary;
  color: $white;
  text-align: center;
}


.card-body {
  padding: 2vh;
  overflow-y: auto;
  height: 67vh;
  border-radius: 0 0 1vmin 1vmin;
  font-size: min(14px, 1.3vh);
  font-family: monospace;
}

.dashboard-panel {
  flex: 1;
  margin-left: 2vmin;
  margin-right: 2vmin;

  .card {
    margin-left: 0;
    margin-right: 0;

    .card-body {
      height: 29vh;
      border-radius: 0 0 1vmin 1vmin;
    }

    textarea.card-body {
      width: 100%;
      height: 26vh; //Not sure why this differs from normal .card-body
      margin-top: -0.1vh;
      border-color: transparent;
      box-sizing: border-box;
      resize: none;
    }

  }
}
